@import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);
}
.item {
    cursor: pointer;
}
.iron-image {
    position: initial !important;
}
.fade.modal.in {
    display: block;
}
.image-gallery-image video,
.image-gallery-image iframe,
.image-gallery-image .spin-wrapper,
.image-gallery-image img {
    width: 100%;
    height: 100%;
    min-height: 347px;
    /*max-height: 760px;*/
}
.image-gallery-image img {
    -o-object-fit: contain;
    object-fit: contain; }
.play-button {
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    height: 60px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.7);
    margin: auto;
    border-radius: 5px;
}
.play-button::after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0px auto;
    border-style: solid;
    border-width: 12.5px 0px 12.5px 20px;
    border-color: transparent transparent transparent rgb(255, 255, 255);
}
#internet-status {
    width: 1%;
    float: left;
}
#internet-status span {
    margin-bottom: -.8em;
    overflow: hidden;
    font-size: 2em;
}
#internet-status span.offline:before {
    content:"\25cf";
    color: red;
}
#internet-status span.online:before {
    content:"\25cf";
    color: green;
}

.modal-dialog.item-modal {
    max-width: 900px !important;
    /*width: 1000px;*/
}
.modal-dialog .modal-content {
    border: none;
    border-radius: unset;
}
.item-details {
    margin-top: 50px;
}
.item-details .name {
    /*margin-bottom: 25px;*/
}
.item-details .sku {
    /*font-style: italic;*/
    font-size: 24px;
}
.item-details .price {
    margin-top: 40px;
    padding-bottom: 25px;
    margin-bottom: 35px;
    border-bottom: 1px solid #ddd;
    font-size: 30px;
    color: #8E7D5A;
    font-weight: 500;
}
.item-details .additional-info {
    margin-top: 15px;
}
.hide {
    display: none !important;
}
.item-details .toggle-attributes {
    color: #0f6674;
    font-style: italic;
    margin-bottom: 20px;
    cursor: pointer;
}
.item-details .link {
    color: #00cac4;
    cursor: pointer;
}
.item-details .link-img {
    margin-left: 10px;
    margin-bottom: 5px;
}
.item-details .attributes .name {
    font-weight: bold;
}
#item-filters {
    margin-bottom: 25px;
}
.ta-c {
    text-align: center;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ccc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@media screen and (min-width: 800px) {
    .main > section {
        height: 100%;
        min-height: 100vh;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .col-sm-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        position: relative;
        width: 100%;        padding-right: 15px;
        padding-left: 15px;
    }
}
.prewiew__name {
    text-align: center;
}
.gallery_h4 {
    text-align: center
}
.prewiew__description {
    word-break: break-word;
}
.sort-close {
    padding-top: 20px;
}
.header__wrap_filters {
    width: 90%;
}
@media screen and (max-width: 1024px) {
    .share-filters {
        margin-bottom: 10px;
    }
}
.gallery__text {
    text-shadow:
        /* first layer at 1px */
            -1px -1px 0px #fff,
            0px -1px 0px #fff,
            1px -1px 0px #fff,
            -1px  0px 0px #fff,
            1px  0px 0px #fff,
            -1px  1px 0px #fff,
            0px  1px 0px #fff,
            1px  1px 0px #fff;

}
.sortable-img {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 160px;
    height: 200px;
    margin: 10px;
    cursor: pointer;
    border: solid 3px #fff;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    -webkit-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    z-index: 10000;
}
.img-container {
    display: flex;
    justify-self: start;
}
.picture {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 10px;
}
.picture-name {
    text-align: center;
    height: 40px;
    overflow: hidden;
    padding: 0px 10px;
}
.picture-name p {
    font-weight: normal !important;
    overflow-wrap: break-word;
}
.item_actions span.item_type {
    color: #ddd;
    font-weight: bold;
    font-size: 1rem;
    vertical-align: super;
}

@media only screen and (max-width: 768px) {
    .sort-close {
        margin: 0 auto;
        width: 50%;
        padding-top: 10px;
    }
}
.col-sd-6.update-confirm {
    margin-right: 20px;
}
.col-sd-6.update-submit {
    margin: 20px auto;
    width: 50%;
}
.sort-close {
    margin: 0 auto;
    width: 50%;
}
.form-control:hover {
    border: 1px solid #00e4dd;
}
.css-1keyrel-container:hover {
    border: 1px solid #00e4dd;
}

.error-message {
    font-size: 10px;
    font-weight: 500;
    color: #cd0000 !important;
    display: block;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
    transition: opacity .3s ease;
}
.form-group.not_valid {
    border: 1px solid #cd0000;
}

.btn_grey {
    background: #fff;
    color: #a29ea1;
    border: 1.5px solid #a29ea1;
    height: 40px;
    padding: 6px 5px;
}

.btn_grey:hover {
    background-image: linear-gradient(to top, #00ddd6, #00cac4);
    color: #fff;
    border: 1.5px solid #00ddd6;

}

.btn_grey[disabled] {
    opacity: .3;
}
.img-container {
    position: relative;
    text-align: center;
    /*color: white;*/
}
.video-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url(images/video.svg);
    background-repeat: no-repeat;
}
.spin-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url(images/spin.svg);
    background-repeat: no-repeat;
}
.image-icon {
    width: 53px;
    height: 53px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url(images/image.svg);
    background-repeat: no-repeat;
}
.form-control:focus {
    box-shadow: none;
}
.btn:focus {
    box-shadow: none;
}
.contact_information_text {
    text-align: center;
    padding: 10px;
}
label.names {
    padding-right: 10px;
}

.col-xs-3.logo {
    padding-right: 10px;
}
.social {
    padding: 10px 0;
}

.catalog__item.catalog__select.col-md-3 {
    padding-right: 10px;
    padding-left: 0;
}
.wrap_buttons {
    display: flex;
    flex-direction: row;
    margin:10px 0 10px 0;
}
.show-contacts {
    margin: 20px auto;
    width: 50%;
}
.shared__title {
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #F5F5F5, 0px 2px 12px #F5F5F5;
    display: flex;
    flex-direction: column;
    padding: 28px;
}

.row.shared__content {
    margin: 34px;
}
.image-gallery-bullet {
    background: #cdd4d4 !important;
    box-shadow: none !important;
    border: 2px solid #fff !important;
    border-radius: 40% !important;
}

.image-gallery-bullet.active {
    background: #00cac4 !important;
}

.Dropzone {
    height: 100px;
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
}

.Highlight {
    background-color: rgb(188, 185, 236);
}

.Icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
}

.FileInput {
    display: none;
}

.form-group.edit-buttons {
    display: flex;
}
.loader {
    position: absolute;
    top: 30%;
    bottom: 0%;
    left: 30%;
    right: 0%;
    z-index: 10001;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    text-align: center;
}
.item-loader,
.item-loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.item-loader {
    z-index: 1000;
    margin: 60px 0 60px 20px;
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 10px solid #f3f3f3;
    border-right: 10px solid #f3f3f3;
    border-bottom: 10px solid #f3f3f3;
    border-left: 10px solid #3498db;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
span.fail {
    z-index: 1001;
    margin: 100px 0 60px 50px;
    font-size: 20px;
    position: absolute;
    color: red !important;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.in_progress {
    opacity: 0.6;
    pointer-events: none;
}
.sortable-container {
    width: 100%;
    position: absolute;
    overflow: hidden;
}
.sortable-container.sort-img-overlay {
    background-color: black !important;
    z-index: 100;
    opacity: 0.3;
    height: 195px;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

hr {
    background-image: -o-linear-gradient(left, transparent, rgba(0, 228, 221, 0.4), transparent);
    background-image: -ms-linear-gradient(left, transparent, rgba(0, 228, 221, 0.4), transparent);
    background-image: -moz-linear-gradient(left, transparent, rgba(0, 228, 221, 0.4), transparent);
    background-image: -webkit-linear-gradient(left, transparent, rgba(0, 228, 221, 0.4), transparent);
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 228, 221, 0.4), rgba(0, 0, 0, 0));
    border: 0 none;
    height: 2px;
    margin: 10px 0;
}

.image-gallery-slides,
.image-gallery-image {
    max-width: 550px;
    max-height: 550px;
    margin: auto;
}

.dots {
    position: absolute;
    top: 25px;
    right: 20px;
    z-index: 10;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
    background-color: #00cac4;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
button:focus {
    outline: none;
}
.add_new_attribute {
    color: #00e4dd !important;
}
.visibility_label {
    margin: 10px 10px 0 0 ;
}

.sweet-alert {
    background-color: white;
    font-family: "Montserrat", sans-serif;
    color: #0f0f0f;
    line-height: 1.3;
    width: 478px;
    padding: 17px;
    border-radius: 5px;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -256px;
    margin-top: -200px;
    overflow: hidden;
    display: block;
    z-index: 99999;
}
.options-multi{
    display: flex;
    align-content: center;
    margin:10px;
    overflow: hidden;
    height: 20px;

}
.options-multi:hover{
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}
.sub-checkbox{
    margin-top: 2px;
}
.sub-text{
    margin-left: 10px;
    margin-bottom: 15px;
}
.sub-text::first-letter{
    text-transform: uppercase;
}
.custom-placeholder {
    display: flex;
    color: hsl(0,0%,50%);
    margin-right: 2px;
    height: 20px;
}
.new-attr-link{
    padding: 10px;
    color: white;
    background: #00cac4;
}
.custom-selected{
    display: flex;
}
.selected-li{
    color: #555555;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin: 5px 0 0 6px;
    padding: 0 6px;
}
.selected-li>span{
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 3px;
}
.password-popup-content {
    margin-top: 20px;
}
.password-input {
    margin-bottom: 15px;
}
span.password-span {
    font-size: 25px;
    font-weight: 400;
}
span#password {
    color: #00cac4;
    font-weight: 500;
}
.row.popup__data.password {
    text-align: center;
}
.prewiew__name {
    text-align: center;
    word-break: break-word;
    margin: 0;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
}
.shared_item{
    margin-bottom:15px;
}
.shared_item.name {
    word-break: break-word;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
}
.shared_item.description {
    color: #575757;
}
.main-attributes {
    margin-bottom: 15px;
    padding: 15px 0;
}
.shared_item.attributes>span {
    float: right;
    color: #575757;
}
.shared_footer {
    background-color: #00CEC5;
    opacity: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.footer-text {
    text-align: center;
    padding: 4px;
    height: auto;
    color: #fff;
    font-size: 10px;
    line-height: 12px;
}
.footer-text a {
    color: #fff;
}
#root{
    height: 100%;
}
.prewiew {
    height: 100%;
}
.btn_green{
    color: #fff;
}
.prewiew {
    height: calc(100% - 30px);
    margin: 0 20px;
}
.shared-contact {
    margin: 10px 0;
    text-align: center;
}
.shared_wrap {
    min-height: calc(100vh - 20px);
}
.shared_container {
    margin-bottom: 20px;
    width: 100%;
}
.image-gallery-thumbnail.active {
    border: none;
}
.image-gallery-thumbnail.active >.image-gallery-thumbnail-inner >img{
    border-bottom: 4px solid  #000;
}
.loading-spinner {
    z-index: 10001;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #00CEC5; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    text-align: center;
}
.loading-spinner.processing_spin {
    width: 100px;
    height: 100px;
}

.gallery-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #00000020;
}
.content-loading{
    position: absolute;
    margin: 0 auto;
    top: 45%;
    left: 45%;
}

.shared__item {
    height: 100%;
}
.progress__bar_spin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    position: absolute;
    /*left: 10%;*/
    top: 50%;
    width: 100%;
}
.progress__bar_spin.percents {
    margin-top: 30px;
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #4a4a4a !important;
}

.spin__controls {
    z-index: 99999;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    align-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.spin__action {
    margin: 0 15px;
}

.zoom-stage {
    width: 143px !important;
    height: 143px !important;
    border-radius: 100px;
    border: 2px solid #000000;
    background-position: 100% 30%;
    background-size: 700%;
}

.error__message {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #00CEC5;
    margin-top: -50px;
    margin-left: -50px;
}

.popup__data canvas{
    max-width: 100%;
    max-height: 351px;
}
.popup__data .with-canvas {
    max-width: 100%;
    max-height: 351px;
}
/*@media only screen and (max-width: 600px) {*/
/*    .shared_wrap canvas{*/
/*       left: -10% !important;*/
/*    }*/
/*}*/
/*@media only screen and (max-width: 320px) {*/
/*    .shared_wrap canvas{*/
/*        left: -20% !important;*/
/*    }*/
/*}*/
.popup_title_block {
    padding-left: 20px;
}

.popup_title {
    font-weight: 800;
    font-size: 20px;
    padding-left: 10px;
}

.popup_wrap {
    padding: 1%;
}

.popup_head {
    padding: 3% 6% 3%;
    border-bottom: 1px solid #D8D8D8;
}

.contact-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_info {
    padding: 4% 25% 4%;
}

.contact_info_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 23px;
}

.image-gallery-image video, .image-gallery-image iframe, .image-gallery-image .spin-wrapper, .image-gallery-image img {
    min-height: 412px;
}

.modal-content {
    background-color: #fff !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    width: 5px !important;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background: rgb(0, 202, 196) !important;
}

.ScrollbarsCustom-Content {
    padding-right: 20px;
}

.image-gallery-thumbnails-wrapper.bottom {
    padding-top: 20px;
}
.shared_item.attributes span {
    color: #575757;
    font-weight: normal;
}
.shared_item.attributes {
    font-weight: 500;
}
.image-gallery-thumbnail{
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
}
.follow-block {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.follow-links {
    display: flex;
}
.follow-text {
}
.follow-links >.follow-icon {
    padding-left: 40px;
}
.image-gallery-image video, .image-gallery-image iframe, .image-gallery-image .spin-wrapper, .image-gallery-image img {
    /*max-height: 330px;*/
}
.modal_catalog-filters {
    display: flex;
    flex: 1 1 auto;
}
.modal_catalog-filters > div {
    width: 50%;
    padding: 20px 0;
}
.modal_catalog-filters > div:first-child {padding-right: 15px; padding-left: 5px;}
.modal_catalog-filters > div:last-child {padding-left: 15px; padding-right: 10px;}
.modal_catalog-filters .filter {margin-bottom: 20px;}
.modal_catalog-filters .filter > label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #24272A;
    display: block;
    margin: 0 0 10px;
}
.modal_catalog-filters .filter > label a {
    font-size: 10px;
    line-height: 12px;
    color: #00CEC5;
    position: relative; top:  3px;
}
.modal_catalog-filters .filter > label a.no-active {opacity: 0.37; cursor: default; text-decoration: none}
.modal_catalog-filters .filter > label a.active {opacity: 0.37; cursor: pointer; text-decoration: none}
.modal_catalog-filters .filter .item-filter {
    display: inline-block;
    margin: 0 5px 10px;
    position: relative; left: -5px;
}
.modal_catalog-filters .filter .item-filter label {
    display: block;
    position: relative;
    cursor: pointer;
}
.modal_catalog-filters .filter .item-filter label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.modal_catalog-filters .filter .item-filter label span,
.modal_catalog-filters .filter .item-filter a {
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 20px;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #A29EA1;
    padding: 2px 15px;
}
.modal_catalog-filters .filter .item-filter label input:checked + span,
.modal_catalog-filters .filter .item-filter a.btn_filter_selected {
    background: #02CAC4;
    border-color: #02CAC4;
    color: #fff;
    user-select: none;
}
.modal_catalog-filters .filter .currency-filter {
    width: 40%;
    display: inline-block;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.modal_catalog-filters .filter .currency-filter select {
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    background-color: #fff;
    padding-right: 20px;
}
.modal_catalog-filters .filter .currency-filter .fa-chevron-down {
    pointer-events: none;
    background-color: transparent;
    color: #D8D8D8;
    position: relative;
    right: 21px;
    top: -1px;
}
.modal_catalog-filters .filter .price-filter {
    width: 59%;
    display: inline-block;
}
.input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #00CEC5 !important;
    border: 1px solid #00CEC5 !important;
    border-radius: 50% !important;
    cursor: pointer;
    display: block;
    height: 5px !important;
    margin-left: unset !important;
    margin-top: -4px !important;
    /* outline: none; */
    position: absolute;
    top: 0 !important;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 5px !important;
}
.input-range__track--active {
    background: #00CEC5 !important;
}

.range-filter{
    margin: 20px 0;
}

label.type__filter {
    color: #A29EA1;
    font-weight: normal;
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.custom-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 80%;
}
.custom-checkbox:checked+label::before {
    border-color: #00CAC4;
    background-color: #00CAC4;
    background-image: url("data:image/svg+xml,%3csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 5L4 8L11 1' stroke='white' stroke-width='2'/%3e%3c/svg%3e");
}

.filters_footer {
    width: 100%;
}

.filter_btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    max-width: 10000px;
    border-radius: 20px;
    display: inline-block;
    padding: 5px 30px;
    width: auto;
    box-shadow: none;
    border-color: inherit;
}

.highlight{
    background-color:#00cac4
}

.modal-header {
    padding: 28px 30px;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.close__popup {
    width: 26px;
    height: 26px;
    background: url(images/icons/close.svg) center no-repeat;
    background-size: 14px;
    align-self: flex-end;
    justify-self:flex-end;
}

.modal__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #24272A;
}
.search__sku {
    border: 1px solid #ECECEC;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #A9A9A9;
    width: 250px;
    padding: 10px 10px 10px 37px;
    background: url(images/icons/ico_search.svg     ) no-repeat left 10px center;
    border-radius: 50px;
}
.search__sku:focus {
    border-color: #0fd8e8;
}
#search-attributes {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #A9A9A9;
    width: 50%;
    padding: 10px 10px 10px 37px;
    background: url(images/icons/ico_search.svg     ) no-repeat left 10px center;
    display: inline-block;
    border-radius: 50px;
}

a.filters_btn {
    padding-left: 20px;
    color: #00CAC4;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact_info_text_value {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: justify;
    word-break: break-all;
}

a.filters_btn svg {
    margin-right: 5px;
}

.image-gallery-slide-wrapper {
    min-height: 412px;
}
.input-range__track {
    background: #c5c5c5;
    height: 2px !important;
}
.input-range__label {
    color: #000 !important;
}
.input-range__label--min, .input-range__label--max {
    bottom: -1rem !important;
    color: #c5c5c5 !important;
}

.spritespin-instance.with-canvas {
    margin: 0 auto;
}

.no-image {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.info_mobile_block {
    display: none;
    text-align: left;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
    background: #fff;
    min-height: 85px;
}
.info_block_media {
    color: #6c6c6c;
    font-size: 14px;
}
.info_block_price {
    padding-top: 5px;
    font-weight: 500;
}
.header__wrap_mobile{
    display: none;
}
.gallery__item img {
    width: 100%;
}
.contact__button_mobile {
    display: none;
}
.search_block {
    display: none;
}
.filter_btn_block {
    justify-content: space-between;
    display: flex;
}
.clear__input {
    width: 15px;
    height: 15px;
    background: url(images/icons/close.svg) center no-repeat;
    position: absolute;
    top:10px;
    right: 20px;
}
.text-filter .clear__input {
    width: 12px;
    height: 12px;
    right: 50px;
}
.search__input_sku {
    position: relative;
}
.search_sku_block {
    display: flex;
    align-items: center;
}
.filter_btn.cancel:hover{
    background: inherit;
    border: 1px solid #00CAC4;
    color: #00CAC4;
}
@media screen and (max-width: 1024px) {
    .header {
        padding: 10px;
    }
    .modal_catalog-filters {
        flex-direction: column;
    }
    .modal_catalog-filters > div {
        width: 100%;
    }
    .gallery__text {
        display: none;
    }

    .info_mobile_block {
        display: block;
        word-break: break-word;
    }
    .header__wrap_mobile {
        display: flex;
    }
    .header__wrap_filters.header__wrap{
        display: none;
    }
    .square__button{
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        text-align: center;
        padding-right: 10px;
        cursor: pointer;
    }
    .square__button .icon_svg {
        padding: 5px;
        width: 30px;
        align-self: center;
    }
    .square__button.search .icon_svg.grey{
        background: #A0A9BC;
    }
    .filter__menu{
        display: flex;
        flex-direction: row;
    }
    .header__item {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header__buttons {
        display: flex;
        flex-direction: row;
    }
    .header__logo img {
        padding-right: 10px;
    }
    .contact__button_mobile {
        display: block;
        position: fixed;
        right: 30px;
        bottom: 40px;
        background: #00CAC4;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 49px;
        padding: 5px  20px;
        color: white;
        z-index: 1001;
    }
    .btn.contact__us {
        display: flex;
        flex-direction: column;
        height: 49px;
        font-weight: 500;
        font-size: 14px;
    }
    .search_block {
        position: relative;
        background: #ffffff;
        border: 4px solid transparent;
        width: 100%;
        z-index: 1001;
        display: none;
    }
    .search_block.show {
        display: block;
    }
    .search_block:after, .search_block:before {
        bottom: 80%;
        right: 5%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .search_block:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 20px;
        margin-left: -20px;
    }
    .search_block:before {
        border-width: 36px;
        margin-left: -36px;
    }
    .close__search {
        padding: 0 15px;
    }
    .search__sku{
        width: 90%;
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #A9A9A9;
        padding: 10px 10px 10px 37px;
        background: url(images/icons/ico_search.svg) no-repeat left 10px center;
        border-radius: 50px;
    }
    .search__input_sku input.search__sku:focus {
        border-color: #0fd8e8 !important;
    }
    .custom__backdrop{
        display: none;
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #575757;
        opacity: 0;
        z-index: 1000;
    }
    .custom__backdrop.show {
        opacity: 0.05;
        display: block;
    }
    .clear__input_mobile {
        width: 15px;
        height: 15px;
        background: url(images/icons/close.svg) center no-repeat;
        position: absolute;
        top:10px;
        right: 20px;
    }
    .item__modal_footer {
        justify-content: space-between;
    }
    .modal_footer__price {
        font-weight: 500;
    }
    .currency_word {
        vertical-align: text-top;
        font-size: 15px;
        line-height: 11px;
    }
    .slider__indexes {
        text-align: center;
    }
    .current__index {
        font-weight: 500;
    }
    .contact_info_text_label,.contact_info_text_value {
        justify-content: center;
        text-align: center;
    }
    .item__col {
        padding-top: 15px;
    }
}
@media screen and (max-width: 320px) {
    .filter_btn {
        margin-top: 10px;
    }
}

.filter__input {
    min-height: 36px;
    padding-left: 8px;
    font-size: 11px;
    border: solid 1px #ececec;
    width: 90%;
    border-radius: 20px;
}

.popup_title{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
    white-space: nowrap;
}

.text-filter {
    position: relative;
}
a.filters_btn:hover {
    color:#00CAC4;
}
a.filters_btn:hover line{
    stroke: #00CAC4;
}

.uploading_block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px    ;
}